import React from 'react';
import { useRouter } from 'next/router';
import LoginForm from './components/LoginForm';
import Footer from '@/components/reusable/Footer';
import BaseLayout from '@/layouts/base-layout';

const Login = () => {
    const router = useRouter();
    const handleLoginSuccess = () => {
        const from = (router.query.from as string) || '/';

        // Ensure redirection after login success
        router.push(from).catch(err => console.error('Redirection failed:', err));
    };

    return (
        <BaseLayout>
            <div className="page-header header-filter"
                 style={{ backgroundImage: `url('/images/login.jpg')`, backgroundSize: 'cover', backgroundPosition: 'top center', height: 'auto', minHeight: '100vh' }}>
                <div className="container wrapper-pannel-content" style={{ minHeight: 800 }}>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-8 ml-auto mr-auto">
                            <LoginForm onLoginSuccess={handleLoginSuccess} /> {/* Pass handler to LoginForm */}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </BaseLayout>
    );
};

export default React.memo(Login);
