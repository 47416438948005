import { styled } from "@mui/material/styles";
import TextField, { TextFieldProps } from "@mui/material/TextField";

// Define a type that includes both TextFieldProps and the custom focusColor prop
type CustomInputProps = TextFieldProps & {
    focusColor?: string;  // Add focusColor as an optional prop
};

export const CustomInput = styled(TextField, {
    shouldForwardProp: (prop) => prop !== "focusColor",
})<CustomInputProps>(({ focusColor }) => ({
    fontFamily: 'Poppins, Arial, sans-serif', // Set font family for the entire TextField

    // Input label when focused
    "& label.Mui-focused": {
        color: focusColor,
        fontFamily: 'Poppins, Arial, sans-serif', // Set font family for focused label
    },
    // Default label style
    "& label": {
        fontFamily: 'Poppins, Arial, sans-serif', // Set font family for label
    },
    // Focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
        borderBottomColor: focusColor,
    },
    // Focused color for input with variant='filled'
    "& .MuiFilledInput-underline:after": {
        borderBottomColor: focusColor,
    },
    // Focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            borderColor: focusColor,
        },
        "& input": {
            fontFamily: 'Poppins, Arial, sans-serif', // Set font family for input field
        },
    },
    // Helper text style
    "& .MuiFormHelperText-root": {
        fontFamily: 'Poppins, Arial, sans-serif', // Set font family for helper text
    },
}));
