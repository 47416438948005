// components/LoginForm.tsx

import React, {useState} from 'react';
import Link from 'next/link';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Button, CircularProgress } from '@mui/material';
import Image from 'next/image';
import { CustomInput } from "@/components/form-elements/CustomInput";
import {useAuth} from "@/context/AuthContext";

// Define the shape of form inputs
interface LoginFormInputs {
    email: string;
    password: string;
}

// Define the props for the LoginForm component
interface LoginFormProps {
    onLoginSuccess: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onLoginSuccess }) => {
    const [generalError, setGeneralError] = useState('')
    const {login} = useAuth()
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Introdu o adresă de email validă')
            .required('Câmpul email este obligatoriu'),
        password: Yup.string()
            .required('Câmpul parolă este obligatoriu')
    });

    const { control, handleSubmit, formState: { errors, isSubmitting } } = useForm<LoginFormInputs>({
        resolver: yupResolver(validationSchema),
    });
    const loginUser = async (formValues: LoginFormInputs) => {
        const { email, password } = formValues;

        try {
            const { user, token, error } = await login({ email, password });

            if (error) {
                setGeneralError(error)
                return;
            }

            if (user && token) {
                localStorage.setItem('user', JSON.stringify(user));
                onLoginSuccess();
            }
        } catch (err:any) {
            setGeneralError(err)
        }
    };

    // Handle form submission
    const onSubmit: SubmitHandler<LoginFormInputs> = async (values) => {
        await loginUser(values);
    };

    // Social login redirect functions
    const socialRedirect = (provider: string) => {
        window.location.href = `${process.env.NEXT_PUBLIC_SOCIAL_LOGIN_API_URL}/${provider}/redirect`;
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="form">
            <div className="card card-login">
                <div className="card-header p-0">
                    <div
                        className="bg-gradient-info shadow-info border-radius-lg py-3 text-center py-4"
                        style={{
                            backgroundImage: 'linear-gradient(to bottom, #12803c, #4CAF50)',
                            borderRadius: '0.5rem'
                        }}
                    >
                        <h4
                            className="font-weight-bolder text-white mt-1 mb-0"
                            style={{ fontSize: 18, fontWeight: 600, letterSpacing: 1 }}
                        >
                            Autentificare
                        </h4>
                        <p className="mb-1 text-sm text-white">Bine ai venit!</p>
                    </div>
                </div>
                <div className="card-body pl-3 pr-3">
                    <div className="mt-3 mb-4 justify-content-center d-flex flex-column">
                        {/* Facebook Login */}
                        <div
                            className="btn btn-link d-flex align-items-center justify-content-center w-100 m-0"
                            style={{ border: '1px solid #808080', borderRadius: 5, cursor: 'pointer' }}
                            onClick={() => socialRedirect('facebook')}
                        >
                            <Image height={25} width={25} src='/images/facebook.svg' alt="facebook-login" />
                            <p className="p-0 m-0 ml-2" style={{ fontWeight: 600 }}>Continuă cu Facebook</p>
                        </div>

                        {/* Google Login */}
                        <div
                            className="btn btn-link d-flex align-items-center justify-content-center w-100 m-0 mt-2"
                            style={{ border: '1px solid #808080', borderRadius: 5, cursor: 'pointer' }}
                            onClick={() => socialRedirect('google')}
                        >
                            <Image height={25} width={25} src='/images/google.svg' alt="google-login" />
                            <p className="p-0 m-0 ml-2" style={{ fontWeight: 600 }}>Continuă cu Google</p>
                        </div>

                        {/* Yahoo Login */}
                        <div
                            className="btn btn-link d-flex align-items-center justify-content-center w-100 m-0 mt-2"
                            style={{ border: '1px solid #808080', borderRadius: 5, cursor: 'pointer' }}
                            onClick={() => socialRedirect('yahoo')}
                        >
                            <Image height={25} width={25} src='/images/yahoo.svg' alt="yahoo-login" />
                            <p className="p-0 m-0 ml-2" style={{ fontWeight: 600 }}>Continuă cu Yahoo</p>
                        </div>
                    </div>
                    <p className="font-weight-bold text-center text-border">sau</p>
                    <div className="mt-3">
                        {/* Email Field */}
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    type="text"
                                    label="Email"
                                    focusColor='#12803c'
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    error={!!errors.email}
                                    helperText={errors.email ? <span style={{ color: 'red' }}>{errors.email.message}</span> : null}
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setGeneralError('');
                                    }}
                                />
                            )}
                        />

                        {/* Password Field */}
                        <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    type="password"
                                    label="Parolă"
                                    focusColor='#12803c'
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    error={!!errors.password}
                                    helperText={errors.password ? <span style={{ color: 'red' }}>{errors.password.message}</span> : null}
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setGeneralError('');
                                    }}
                                />
                            )}
                        />
                        {generalError ? <p style={{ color: 'red' }}>{generalError}</p> : ''}

                        {/* Submit Button */}
                        <Button
                            type="submit"
                            variant="contained"
                            color="success"
                            disabled={isSubmitting}
                            fullWidth
                            style={{
                                height: '45px',
                                marginBottom: 30,
                                marginTop: 20,
                                backgroundColor: "#12803c"
                            }}
                        >
                            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Autentificare'}
                        </Button>

                        {/* Forgot Password Link */}
                        <p className="text-center mb-4">
                            <Link href="/recover-password" passHref legacyBehavior>
                                <a>Ai uitat parola?</a>
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default LoginForm;
